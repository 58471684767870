var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fixed w-full z-10 py-4 bg-white shadow-md dark:bg-gray-800",class:{
    'bg-purple-50': _vm.currentUser.role == 'Admin',
    'bg-yellow-50': _vm.currentUser.role == 'Printer',
  }},[_c('div',{staticClass:"flex items-center justify-between h-full px-6 mx-auto",class:{
      'text-purple-600': _vm.currentUser.role == 'Admin',
      'text-yellow-700': _vm.currentUser.role == 'Printer',
    }},[_c('div',{staticClass:"flex justify-left flex-1 ml-64"},[(_vm.currentUser.role == 'Admin')?_c('span',{staticClass:"font-bold text-xl"},[_vm._v("Administrator ")]):_vm._e(),(_vm.currentUser.role == 'Printer')?_c('span',{staticClass:"font-bold text-xl"},[_vm._v("Printer ")]):_vm._e()]),_c('ul',{staticClass:"flex items-center flex-shrink-0 space-x-6"},[_c('li',{staticClass:"relative"},[_c('button',{staticClass:"align-middle rounded-full focus:shadow-outline-purple focus:outline-none",attrs:{"aria-label":"Account","aria-haspopup":"true"}},[_c('div',{staticClass:"flex w-10 h-10 rounded-full bg-purple-700 text-center",style:({
              'background-color': _vm.stringToColor(_vm.currentUser.user),
            })},[_c('div',{staticClass:"mx-auto my-auto text-lg text-white"},[_vm._v(" "+_vm._s(_vm.currentUser.user[0].toUpperCase())+" ")])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }